<template>
  <div :class="$style.info">
    <div :class="$style.content">
      <div :class="$style.header">运城文旅云认证</div>
      <div :class="$style.middle">
        <img :class="$style.logo" :src="oss + '/manage/icon/default/auth.png'" alt="" />
        <p :class="$style.title">机构信息已经认证</p>
        <p :class="$style.tip">认证申请时间：{{ data.updated_at }}</p>
        <div :class="$style.line" />
      </div>
      <div :class="$style.footer">
        <p :class="$style.title">资质信息</p>
        <div :class="$style.box">
          <div :class="$style.left">机构类型</div>
          <div :class="$style.right">{{ data.organization_type_name }}</div>
        </div>
        <div :class="$style.box">
          <div :class="$style.left">机构名称</div>
          <div :class="$style.right">{{ data.certificate_name }}</div>
        </div>
        <div :class="$style.box">
          <div :class="$style.left">机构代码</div>
          <div :class="$style.right">{{ data.certificate_code }}</div>
        </div>

        <p :class="$style.title">法人信息</p>
        <div :class="$style.box">
          <div :class="$style.left">法人姓名</div>
          <div :class="$style.right">{{ data.corporate_name }}</div>
        </div>
        <div :class="$style.box">
          <div :class="$style.left">证件类型</div>
          <div :class="$style.right">{{ data.corporate_type | typeName }}</div>
        </div>
        <div :class="$style.box">
          <div :class="$style.left">证件号码</div>
          <div :class="$style.right">{{ data.corporate_number }}</div>
        </div>

        <p :class="$style.title">联系人信息</p>
        <div :class="$style.box">
          <div :class="$style.left">联系人姓名</div>
          <div :class="$style.right">{{ data.contacts }}</div>
        </div>
        <div :class="$style.box">
          <div :class="$style.left">联系人电话</div>
          <div :class="$style.right">{{ data.datacontacts_tel }}</div>
        </div>

        <p :class="$style.edit">
          以上信息基于认证时提交信息展示，如果发现信息需要更新，请点击
          <span class="primary cursor" @click="onChoose()">修改认证</span>
        </p>
      </div>
    </div>
    <validateModal :visible.sync="validateVisible" :params="params" @submit="validate" />
    <bj-modal
      title="修改认证"
      :visible="visible"
      :width="520"
      :body-style="{ height: '500px' }"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div :class="$style.modal">
        <div :class="[$style.box, current === 1 ? $style.active : null]" @click="onCuurent(1)">
          <p :class="$style.title">认证信息修改</p>
          <p :class="$style.tip">仅补充/修改相关认证信息，但不可修改认证主体信息中的机构类型/机构名称/机构代码等。</p>
          <div :class="$style.check" />
          <i :class="$style.icon" class="ri-check-line" />
        </div>
        <div :class="[$style.box, current === 2 ? $style.active : null]" @click="onCuurent(2)">
          <p :class="$style.title">认证主体变更</p>
          <p :class="$style.tip">
            将文化云主体进行变更，变更主体需签署文化云主体变更协议，协议双方加盖红章，并原件拍照上传；
          </p>
          <div :class="$style.check" />
          <i :class="$style.icon" class="ri-check-line" />
        </div>
      </div>
    </bj-modal>
  </div>
</template>

<script>
import validateModal from '@/components/validateModal'
import { platformService } from '@/service'

const service = new platformService()

export default {
  name: 'Info',
  components: {
    validateModal,
  },
  filters: {
    typeName(val) {
      switch (val) {
        case 1:
          return '中国大陆居民身份证'
        case 2:
          return '中国香港居民来往内地通行证'
        case 3:
          return '中国澳门居民来往内地通行证'
        case 4:
          return '中国台湾居民来往内地通行证'
        case 5:
          return '其他国家或地区居民护照'
      }
    },
  },
  data() {
    return {
      data: {},
      visible: false,
      validateVisible: false,
      current: 1,
      params: {},
      valid_key: null,
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    async getInfo() {
      try {
        const { data } = await service.getAuthentication()
        this.data = data.certificate
      } catch (e) {}
    },
    onChoose() {
      this.validateVisible = true
    },
    validate(e) {
      this.valid_key = e.valid_key
      this.visible = true
    },
    handleOk() {
      if (this.current === 1) {
        this.$router.push({
          name: 'systemPlatformCertificate',
          query: {
            type: 'edit',
            key: this.valid_key,
          },
        })
      }
      if (this.current === 2) {
        this.$router.push({
          name: 'systemPlatformCertificate',
          query: {
            type: 'modify',
            key: this.valid_key,
          },
        })
      }
    },
    handleCancel() {
      this.visible = false
    },
    onCuurent(_type) {
      this.current = _type
    },
  },
}
</script>

<style lang="less" module>
.info {
  .content {
    width: 800px;
    min-height: calc(100vh - 152px);
    margin: 0 auto;
    background-color: #fff;

    .header {
      height: 50px;
      color: #fff;
      font-size: 16px;
      line-height: 50px;
      text-align: center;
      background-color: @primary-color;
    }

    .middle {
      text-align: center;

      .logo {
        width: 60px;
        height: 60px;
        margin: 40px 0 20px;
      }

      .title {
        margin-bottom: 10px;
        color: #000;
        font-size: 14px;
      }

      .tip {
        margin-bottom: 20px;
        color: #5c5c5c;
        font-size: 12px;
      }

      .line {
        width: 50px;
        height: 1px;
        margin: 0 auto;
        border-top: 1px solid @primary-color;
      }
    }

    .footer {
      padding-left: 100px;

      .title {
        margin-top: 40px;
        color: #000;
        font-size: 16px;
      }

      .box {
        display: flex;
        margin-top: 14px;

        .left {
          width: 140px;
          color: #5c5c5c;
          font-size: 14px;
        }

        .right {
          color: #000;
          font-size: 14px;
        }
      }

      .edit {
        margin-top: 40px;
        margin-bottom: 100px;
        color: #5c5c5c;
        font-size: 12px;
      }
    }
  }
}

.modal {
  .box {
    position: relative;
    width: 100%;
    height: 96px;
    margin-bottom: 20px;
    padding: 14px;
    border: 1px solid #eee;
    cursor: pointer;
    user-select: none;

    .check {
      position: absolute;
      right: 0;
      bottom: 0;
      display: none;
      width: 0;
      height: 0;
      border-bottom: 40px solid @primary-color;
      border-left: 40px solid transparent;
    }

    .icon {
      position: absolute;
      right: 2px;
      bottom: -5px;
      display: none;
      color: #fff;
      font-size: 18px;
    }

    .title {
      color: #000;
      font-size: 14px;
    }

    .tip {
      color: #5c5c5c;
      font-size: 12px;
    }
  }

  .active {
    border: 1px solid @primary-color;

    .check,
    .icon {
      display: block;
    }
  }
}
</style>
